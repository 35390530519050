import React from 'react';
import { Container } from 'reactstrap';
import StreamingSession from '../../components/StreamingSession';
import { useAuth } from '../../contexts/authContext';

const StreamingPage = () => {
  const { currentUser } = useAuth();

  return (
    <Container>
      <h2 className="my-4">Streaming Session</h2>
      {currentUser ? (
        <StreamingSession uid={currentUser.uid} />
      ) : (
        <p>Please login to access streaming features</p>
      )}
    </Container>
  );
};

export default StreamingPage; 