import React, { useEffect, useState } from "react";
import { db } from '../../firebase/firebase';
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label } from "reactstrap";

// Trend Display Page
const TrendDisplayPage = () => {
  const [trends, setTrends] = useState({});
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTrend, setSelectedTrend] = useState(null);
  const [newTrend, setNewTrend] = useState("up");
  const [pivotHigh, setPivotHigh] = useState(-1);
  const [pivotLow, setPivotLow] = useState(-1);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const toggleModal = () => setModalOpen((prevState) => !prevState);

  useEffect(() => {
    // const symbols = ['MES', 'MNQ', 'MYM', 'M2K', 'MGC', 'MCL', 'MBT', 'M6A', 'M6E', 'MHG', 'MNG', "QI" , 'NG'];

    const symbols = ['MES', 'MNQ', 'MYM', 'M2K'];
    const timeFrames = ['weekly', '1 day', '4 hours', '1 hour', '15 minutes', '5 minutes'];
    const unsubscribes = [];

    symbols.forEach((symbol) => {
      const symbolTrends = {};
      timeFrames.forEach((timeFrame) => {
        const trendDocRef = doc(db, `trend/${symbol}/TF/${timeFrame}`);
        const unsubscribe = onSnapshot(trendDocRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            symbolTrends[timeFrame] = {
              trend: data.trend,
              pivotHigh: data.pivotHigh,
              pivotLow: data.pivotLow,
              time: data.time?.seconds ? new Date(data.time.seconds * 1000).toLocaleString() : 'N/A'
            };
            setTrends((prevTrends) => ({
              ...prevTrends,
              [symbol]: {
                ...prevTrends[symbol],
                ...symbolTrends,
              },
            }));
          }
        });
        unsubscribes.push(unsubscribe);
      });
    });

    setLoading(false);

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, []);

  const getButtonColor = (trend) => {
    if (trend === 'up') return 'success';
    if (trend === 'down') return 'danger';
    if (trend === 'sideways') return 'warning';
    return 'secondary';
  };

  const handleTrendClick = (symbol, timeFrame, trendData) => {
    setSelectedTrend({ symbol, timeFrame, ...trendData });
    setNewTrend(trendData.trend);
    setPivotHigh(trendData.pivotHigh || "");
    setPivotLow(trendData.pivotLow || "");
    toggleModal();
  };

  const handleSave = async () => {
    if (selectedTrend) {
      const { symbol, timeFrame } = selectedTrend;
      const trendDocRef = doc(db, `trend/${symbol}/TF/${timeFrame}`);
      await updateDoc(trendDocRef, {
        trend: newTrend,
        pivotHigh: parseFloat(pivotHigh),
        pivotLow: parseFloat(pivotLow),
      });
      toggleModal();
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {Object.entries(trends).map(([symbol, timeFrameTrends]) => (
          <div key={symbol} style={{ marginBottom: '20px' }}>
            <h6>{symbol}</h6>
            <div style={{ display: 'flex', gap: '10px' }}>
              {Object.entries(timeFrameTrends || {}).map(([timeFrame, trendData]) => (
                <Button
                  key={timeFrame}
                  color={getButtonColor(trendData.trend)}
                  style={{ padding: '10px 20px' }}
                  onClick={() => handleTrendClick(symbol, timeFrame, trendData)}
                >
                  {timeFrame}: {trendData.trend}
                  <br />
                  {trendData.time}
                </Button>
              ))}
            </div>
          </div>
        ))}
      </div>
      {selectedTrend && (
        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Edit Trend</ModalHeader>
          <ModalBody>
            <Label for="trendSelect">Trend</Label>
            <Input
              type="select"
              id="trendSelect"
              value={newTrend}
              onChange={(e) => setNewTrend(e.target.value)}
            >
              <option value="up">Up</option>
              <option value="down">Down</option>
              <option value="sideways">Sideways</option>
            </Input>
            <Label for="pivotHigh" style={{ marginTop: '10px' }}>Pivot High</Label>
            <Input
              type="number"
              id="pivotHigh"
              value={pivotHigh}
              onChange={(e) => setPivotHigh(e.target.value)}
            />
            <Label for="pivotLow" style={{ marginTop: '10px' }}>Pivot Low</Label>
            <Input
              type="number"
              id="pivotLow"
              value={pivotLow}
              onChange={(e) => setPivotLow(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSave}>Save</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default TrendDisplayPage;
