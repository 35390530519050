import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import Grid from './components/grid';
import StreamingPage from './pages/StreamingPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import Header from "./components/header";
import Systems from "./components/systems";
import SystemScreen from "./components/systems-screen";
import Home from "./components/home";
import Screen from "./components/screen";
import Checkout from "./components/checkout";
import Profile from "./components/profile";
import Problems from "./components/problems";
import Privacy from "./components/privacy";
import Map from "./components/map";
import Display from "./components/display";
import DisplayInactive from "./components/display_inactive";
import ScorePage from "./components/score";
import TrendDisplayPage from "./components/trend";
import DailyATRPage from "./components/atr";
import LandingPage from "./components/landing";
import AudioRecorder from "./components/audio";
import { AuthProvider } from "./contexts/authContext";
import Footer from "./components/footer";
import TermsOfService from "./components/terms-of-service";
import { useRoutes } from "react-router-dom";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/css/argon-design-system-react.css";
import CurvePage from "./components/curve";

const App = () => {
  const routesArray = [
    {
      path: "*",
      element: <LandingPage  />,
    },
    {
      path: "/problems",
      element: <Problems />,
    },
    {
      path: "/landing",
      element: <LandingPage />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/home",
      element: <Home />,
    }, 
    {
      path: "/systems",
      element: <Systems />,
    }, 
    {
      path: "/systems-screen",
      element: <SystemScreen />,
    }, 
    {
      path: "/screen",
      element: <Screen />,
    }, 
    { path: "/checkout",
      element: <Checkout />,
    },
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/terms-of-service",
      element: <TermsOfService />,
    },
    {
      path: "/map",
      element: <Map />,
    },
    {
      path: "/audio",
      element: <AudioRecorder/>,
    },
    {
      path: "/grid",
      element: <Grid/>,
    },
    {
      path: "/atr",
      element: <DailyATRPage />
    },
    {
      path: "/display",
      element: <Display/>,
    },
    {
      path: "/display_inactive",
      element: <DisplayInactive/>,
    },
    {
      path: "/score",
      element: <ScorePage/>,
    },
    {
      path: "/trend",
      element: <TrendDisplayPage/>,
    },
    {
      path: "/streaming",
      element: <StreamingPage />,
    },
    {
      path: "/curve",
      element: <CurvePage />,
    }
  ];
  
  let routesElement = useRoutes(routesArray);

  return (
    <AuthProvider>
      <Header />
      <div className="w-full flex flex-col">{routesElement}</div>
      <Footer />
    </AuthProvider>
  );
};

export default App;
