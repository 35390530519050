import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { db } from '../../firebase/firebase';
import { addDoc, doc, getDoc, collection, serverTimestamp} from "firebase/firestore";
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

// Score Page Component
const ScorePage = () => {
  const [searchParams] = useSearchParams();
  const initialId = searchParams.get("q");
  const [scoreId, setScoreId] = useState(initialId);
  const [title, setTitle] = useState("");
  const [score, setScore] = useState(0);
  const [selectedZone, setSelectedZone] = useState('SUPPLY ZONE');
  const [selectedSymbol, setSelectedSymbol] = useState('MES');
  const [selectedTrendTimeFrame, setSelectedTrendTimeFrame] = useState('weekly');
  const [selectedCurveTimeFrame, setSelectedCurveTimeFrame] = useState('monthly');
  const [dropdownOpen, setDropdownOpen] = useState({ symbol: false, trend: false, curve: false });
  const [selection, setSelection] = useState({
    strength: null,
    time: null,
    freshness: null,
    trend: null,
    curve: null,
  });

  const toggleDropdown = (key) => {
    setDropdownOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const criteria = [
    {
      title: "HOW DID PRICE LEAVE THE ZONE? (STRENGTH)",
      options: [
        { label: "Move out AND Breakout", score: 2 },
        { label: "Move out ONLY OR Breakout ONLY", score: 1 },
        { label: "Neither", score: 0 },
      ],
      key: "strength",
    },
    {
      title: "HOW MUCH TIME DID PRICE SPEND AT THE ZONE? (TIME)",
      options: [
        { label: "1-3 Basing Candles", score: 1 },
        { label: "4-6 Basing Candles", score: 0.5 },
        { label: ">6 Basing Candles", score: 0 },
      ],
      key: "time",
    },
    {
      title: "HAS PRICE RETURNED TO THE ZONE? (FRESHNESS)",
      options: [
        { label: "Zone not Pierced", score: 2 },
        { label: "Pierced 50% or Less", score: 1 },
        { label: "Pierced Greater Than 50%", score: 0 },
      ],
      key: "freshness",
    },
    {
      title: "WHAT IS THE TREND?",
      options: selectedZone === 'SUPPLY ZONE' ? [
        { label: "Down Trend", score: 2 },
        { label: "Sideways", score: 1 },
        { label: "Up Trend", score: 0 },
      ] : [
        { label: "Up Trend", score: 2 },
        { label: "Sideways", score: 1 },
        { label: "Down Trend", score: 0 },
      ],
      key: "trend",
    },
    {
      title: "WHERE IN THE CURVE IS THE ZONE LOCATED?",
      options: selectedZone === 'SUPPLY ZONE' ? [
        { label: "High on Curve", score: 1 },
        { label: "Middle of Curve", score: 0.5 },
        { label: "Low on Curve", score: 0 },
      ] : [
        { label: "Low on Curve", score: 1 },
        { label: "Middle of Curve", score: 0.5 },
        { label: "High on Curve", score: 0 },
      ],
      key: "curve",
    },
  ];

  const handleSelection = (key, option) => {
    setSelection((prevSelection) => {
      const newSelection = { ...prevSelection, [key]: option.score };
      const newScore = Object.values(newSelection).reduce((acc, val) => acc + (val || 0), 0);
      setScore(newScore);
      return newSelection;
    });
  };

  const saveScoreToFirebase = async () => {
    try {
      const docRef = await addDoc(collection(db, "scores"), {
        title,
        score,
        selection,
        selectedZone,
        selectedSymbol,
        selectedTrendTimeFrame,
        selectedCurveTimeFrame,
        timestamp: serverTimestamp(),
      });
      setScoreId(docRef.id);
    } catch (error) {
      console.error("Error saving score: ", error);
    }
  };

  useEffect(() => {
    if (initialId) {
      loadScoreFromFirebase(initialId);
    }
  }, [initialId]);

  const loadScoreFromFirebase = async (id = prompt("Enter Score ID to load:")) => {
    if (id) {
      try {
        const docRef = doc(db, "scores", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setTitle(data.title || "");
          setScore(data.score || 0);
          setSelection(data.selection || {
            strength: null,
            time: null,
            freshness: null,
            trend: null,
            curve: null,
          });
          setSelectedZone(data.selectedZone || 'SUPPLY ZONE');
          setSelectedSymbol(data.selectedSymbol || 'MES');
          setSelectedTrendTimeFrame(data.selectedTrendTimeFrame || 'weekly');
          setSelectedCurveTimeFrame(data.selectedCurveTimeFrame || 'monthly');
          setScoreId(id);
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error loading score: ", error);
      }
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="Enter Score Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ padding: "5px", width: "300px", marginRight: "10px" }}
        />
        <Button color="info" onClick={saveScoreToFirebase}>Save Score</Button>
        <Button color="secondary" onClick={() => loadScoreFromFirebase()} style={{ marginLeft: "10px" }}>Load Score by ID</Button>
      </div>

      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        Symbol
        <Dropdown isOpen={dropdownOpen.symbol} toggle={() => toggleDropdown('symbol')}>
          <DropdownToggle caret>
            {selectedSymbol}
          </DropdownToggle>
          <DropdownMenu>
          {/* {['MES', 'MNQ', 'M2K', 'MYM', 'MCL', 'MGC', 'MBT', 'M6A', 'M6E', 'MHG', 'MNG', 'QI'].map((symbol) => ( */}

            {['MES', 'MNQ', 'M2K', 'MYM',].map((symbol) => (
              <DropdownItem key={symbol} active={selectedSymbol === symbol} onClick={() => setSelectedSymbol(symbol)}>{symbol}</DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        Curve
        <Dropdown isOpen={dropdownOpen.curve} toggle={() => toggleDropdown('curve')}>
          <DropdownToggle caret>
            {selectedCurveTimeFrame}
          </DropdownToggle>
          <DropdownMenu>
            {['monthly', 'weekly', 'daily', '4 hour', '1 hour', '30 min', '15 mins'].map((timeFrame) => (
              <DropdownItem key={timeFrame} onClick={() => setSelectedCurveTimeFrame(timeFrame)}>{timeFrame}</DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        Trend
        <Dropdown isOpen={dropdownOpen.trend} toggle={() => toggleDropdown('trend')}>
          <DropdownToggle caret>
            {selectedTrendTimeFrame}
          </DropdownToggle>
          <DropdownMenu>
            {['weekly', 'daily', '4 hour', '1 hour', '30 min', '15 mins'].map((timeFrame) => (
              <DropdownItem key={timeFrame} onClick={() => setSelectedTrendTimeFrame(timeFrame)}>{timeFrame}</DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
       
      </div>

      <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px", marginBottom: "20px" }}>
        <Button color={selectedZone === 'SUPPLY ZONE' ? 'danger' : 'secondary'} onClick={() => setSelectedZone('SUPPLY ZONE')}>SUPPLY</Button>
        <Button color={selectedZone === 'DEMAND ZONE' ? 'success' : 'secondary'} onClick={() => setSelectedZone('DEMAND ZONE')}>DEMAND</Button>
      </div>

      {criteria.map((criterion, index) => (
        <div key={index} style={{ marginBottom: "20px" }}>
          <h4>{criterion.title}</h4>
          <div style={{ display: "flex", gap: "10px" }}>
            {criterion.options.map((option, idx) => (
              <Button
                key={idx}
                color={selection[criterion.key] === option.score ? "primary" : "secondary"}
                onClick={() => handleSelection(criterion.key, option)}
              >
                {option.label}
              </Button>
            ))}
          </div>
        </div>
      ))}

      <div style={{ position: "fixed", top: "20px", right: "20px", backgroundColor: score >= 7 ? '#008000' : '#b22222', color: "white", padding: "20px", borderRadius: "10px" }}>
        <h3>Scorecard</h3>
        <h2>{score} / 10</h2>
        <ul>
          {Object.entries(selection).map(([key, value]) => (
            <li key={key}>{key.charAt(0).toUpperCase() + key.slice(1)}: {value ?? 'N/A'} / {criteria.find(criterion => criterion.key === key)?.options[0].score}</li>
          ))}
        </ul>
        <p>{selectedZone}</p>
        {scoreId && (
          <>
            <p>Document ID: {scoreId}</p>
            <p>URL: <a href={`${window.location.origin}/score?q=${scoreId}`} target="_blank" rel="noopener noreferrer">{`${window.location.origin}/score?q=${scoreId}`}</a></p>
          </>
        )}
      </div>
    </div>
  );
};

export default ScorePage;
