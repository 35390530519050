import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { doc, setDoc, onSnapshot } from 'firebase/firestore';
import { Button } from 'reactstrap';

const StreamingSession = ({ uid }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [sessionData, setSessionData] = useState(null);
  const [status, setStatus] = useState('Disconnected');

  useEffect(() => {
    if (!uid) return;

    const sessionRef = doc(db, 'streaming', uid);
    const unsubscribe = onSnapshot(sessionRef, (doc) => {
      if (doc.exists()) {
        setSessionData(doc.data());
        setStatus(doc.data().isActive ? 'Connected' : 'Disconnected');
        setIsConnected(doc.data().isActive);
      }
    });

    return () => unsubscribe();
  }, [uid]);

  const handleConnect = async () => {
    try {
      const sessionRef = doc(db, 'streaming', uid);
      await setDoc(sessionRef, {
        isActive: true,
        timestamp: new Date().toISOString(),
        youtubeLink: sessionData?.youtubeLink || ''
      });
      setStatus('Connected');
      setIsConnected(true);
    } catch (error) {
      console.error('Error connecting:', error);
      setStatus('Connection failed');
    }
  };

  const handleDisconnect = async () => {
    try {
      const sessionRef = doc(db, 'streaming', uid);
      await setDoc(sessionRef, {
        isActive: false,
        timestamp: new Date().toISOString(),
        youtubeLink: sessionData?.youtubeLink || ''
      });
      setStatus('Disconnected');
      setIsConnected(false);
    } catch (error) {
      console.error('Error disconnecting:', error);
      setStatus('Disconnection failed');
    }
  };

  if (!uid) {
    return <div>Authentication required</div>;
  }

  return (
    <div className="streaming-session" style={{ margin: '20px' }}>
      <div style={{ marginBottom: '20px' }}>
        <Button 
          color="success" 
          onClick={handleConnect} 
          disabled={isConnected}
          style={{ marginRight: '10px' }}
        >
          Connect
        </Button>
        <Button 
          color="danger" 
          onClick={handleDisconnect} 
          disabled={!isConnected}
        >
          Disconnect
        </Button>
        <span style={{ marginLeft: '20px' }}>
          Status: {status}
        </span>
      </div>

      {sessionData?.youtubeLink && (
        <div className="video-container" style={{ width: '100%', maxWidth: '800px' }}>
          <iframe
            width="100%"
            height="450"
            src={`https://www.youtube.com/embed/live_stream?channel=${getYoutubeVideoId(sessionData.youtubeLink)}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      )}
    </div>
  );
};

// Helper function to extract video ID from YouTube URL
const getYoutubeVideoId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

export default StreamingSession; 